import * as types from '../actions/types';
const INITIAL = {
  isShowTooltips: true
};

const commReducer = (state = INITIAL, action) => {
  switch (action.type) {
    case types.SHOW_TOOLTIPS:
      return {
        ...state,
        isShowTooltips: true
      };
    case types.HIDE_TOOLTIPS:
      return {
        ...state,
        isShowTooltips: false
      };
    default:
      return state;
  }
};

export default commReducer;
