import * as types from '../actions/types';
const INITIAL = {
  isDrawerOpen: false
};

const drawerReducer = (state = INITIAL, action) => {
  switch (action.type) {
    case types.OPEN_DRAWER:
      return {
        ...state,
        isDrawerOpen: true
      };
    case types.CLOSE_DRAWER:
      return {
        ...state,
        isDrawerOpen: false
      };
    default:
      return state;
  }
};

export default drawerReducer;
