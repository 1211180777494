import React from 'react';
import { withNamespaces } from 'react-i18next';
import styles from './index.module.css';
//
// Shows text status to the left of the base user's chat bubble.
// Hiển thị trạng thái văn bản ở bên trái của bong bóng trò chuyện của người dùng cơ sở.
// -----------------------
const TextRead = ({ t }) => (
  <div className={styles.read}>{t('chat-message-text-read')}</div>
);

export default withNamespaces('chat', { wait: true })(TextRead);
